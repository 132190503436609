import styles from './About.module.scss'
import { useScrollAnimation } from '@/shared/lib/hooks/useScrollAnimation';
import {InfoSection} from "@/shared/ui/InfoSection";
import { useTranslation } from 'react-i18next';

export const About = () => {
	const { ref, classes } = useScrollAnimation();
	const { t } = useTranslation();

	return (
		<InfoSection
			sectionRef={ref}
			className={`${styles.root} container ${classes}`}
			// @ts-ignore
			title={t('welcome_page.about.title')}
			// @ts-ignore
			description={t('welcome_page.about.description')}
			view={'lightBlue'}
		/>
	)
}