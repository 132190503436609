import { useScrollAnimation } from '@/shared/lib/hooks/useScrollAnimation';
import { images } from '@/shared/lib/images';

import styles from './Game.module.scss'
import { useTranslation } from 'react-i18next';

export const Game = () => {
	const { ref, classes, isShowed } = useScrollAnimation();
	const { t } = useTranslation();

	return (
		<section className={`${styles.root} container`}>
			<div ref={ref} className={`${styles.wrapper} ${classes}`}>
				<h3 className={styles.title}>
					{/* @ts-ignore */}
					{t('welcome_page.cflats_game.title')}
				</h3>
				<p className={styles.description}>
					{/* @ts-ignore */}
					{t('welcome_page.cflats_game.description.0')}
					<br />
					<br />
					{/* @ts-ignore */}
					{t('welcome_page.cflats_game.description.1')}
				</p>
			</div>
			<div className={styles.image}>
				<img
					className={`${styles['image-left']} ${isShowed ? styles['is-active'] : ''}`}
					src={`${images.main.game.phoneLeft}.png`}
					alt={'phone-left'}
					loading='lazy'
				/>
				<img
					className={`${styles['image-right']} ${isShowed ? styles['is-active'] : ''}`}
					src={`${images.main.game.phoneRight}.png`}
					alt={'phone-left'}
					loading='lazy'
				/>
			</div>
		</section>
	)
}