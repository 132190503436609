import { IconBase } from '@/shared/ui/IconBase';
import { useMemo } from 'react';
import { Button } from '@/shared/ui/Button';
import { images } from '@/shared/lib/images';
import { WebpImage } from '@/shared/ui/WebpImage';

import styles from './Info.module.scss'
import {useNavigate, useNavigation} from "react-router-dom";
import { useTranslation } from 'react-i18next';

export const Info = () => {
	const containerClasses = useMemo(() => [
		styles.wrapper,
		window.innerWidth >= 769 ? 'container' : ''
	].join(' '), [])

	return <section className={styles.root}>
		<div className={containerClasses}>
			<PromoCard />
			<MintCard />
		</div>
	</section>
}

const PromoCard = () => {
	const { t } = useTranslation();

	return (
	<article className={styles['promo-card']}>
		<video
			className={`${styles.video} lazy`}
			autoPlay={true}
			playsInline={true}
			loop={true}
			muted={true}
			poster={`${images.main.info.game.preview}.png`}
			preload='none'
		>
			<source src={'/videos/promo.mp4'} type={'video/mp4'} />
		</video>
		<div className={styles['card-container']}>
			<p className={styles.title}>
				{/* @ts-ignore */}
				{t('welcome_page.banners.cflats_game.title')}
			</p>
			<p className={styles.description}>
				{/* @ts-ignore */}
				{t('welcome_page.banners.cflats_game.description')}
				<IconBase type={'icon-rocket'} />
			</p>
			<Button 
				className={styles.button}
				href='https://t.me/Cflatsbot/cflatsgame?startapp'
			>
				{/* @ts-ignore */}
				{t('buttons.play2earn')}
			</Button>
		</div>
	</article>
	);
}

const MintCard = () => {
	const navigate = useNavigate();
	const {t} = useTranslation();
	
	{/* @ts-ignore */}
	const [discover, ourNft] = t('welcome_page.banners.discover_nft.title')
	.split("\n").map((str: string) => str.trim());

	return (
		<article className={styles['mint-card']}>
			{Array(6).fill(1).map((_, key) => (
				<div key={key} className={styles[`image-${key + 1}`]}>
					<WebpImage
						src={images.main.info.mint[`${key + 1}` as keyof typeof images.main.info.mint]}
						alt={`image-${key + 1}`}
					/>
				</div>
			))}
			<div className={styles['card-container']}>
				<p className={styles.title}>
					{discover}
					<br />
					{ourNft}
				</p>
				<Button
					className={styles.button}
					// onClick={() => navigate('/mint')}
				>
					{/* @ts-ignore */}
					{t('buttons.mint_page')}
				</Button>
			</div>
		</article>
	)
}