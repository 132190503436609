import { Link } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";

import { ModalConnectWallet } from "@/features/ModalConnectWallet/ModalConnectWallet";
import { TokenSaleModal } from "@/features/TokenSaleModal";

import { WebpImage } from "@/shared/ui/WebpImage";
import { images } from "@/shared/lib/images";

import { Burger } from "./ui";
import styles from "./Header.module.scss";
import { toast } from "react-toastify";
import { useUnavailbleToken } from "@/shared/lib/hooks/useUnavailableToken";
import { useTranslation } from "react-i18next";

// @ts-ignore
import { FaChevronDown } from "react-icons/fa";

const IS_TOKEN_AVAILABLE = false;

export type HeaderProps = {
	type?: "dapp" | "main";
};

export const Header: React.FC<HeaderProps> = ({ type = "main" }) => {
	const { trigger } = useUnavailbleToken();
	const { t } = useTranslation();
	const lang = localStorage.getItem("saved-lang");

	const [isOpen, setIsOpen] = useState(false);
	const [isConnectWallet, setIsConnectWallet] = useState(false);
	const [isTokenSale, setIsTokenSale] = useState(false);

	const [isBackground, setIsBackground] = useState(false);

	const logo = useMemo(() => {
		if (type === "dapp") {
			return images.main.header["logo-dapp"];
		}

		if (lang === "ru") {
			return images.main.header["logo-ru"];
		}
		return images.main.header.logo;
	}, [type, lang]);

	function onOpenWallet() {
		if (window.innerWidth < 769) {
			setIsBackground(true);
		}
		setIsConnectWallet(true);
	}

	return (
		<>
			<header
				className={`${styles.root} ${isBackground ? styles["is-background"] : ""
					}`}
			>
				<div
					className={`${styles.container} container ${type === "dapp" ? styles["is-wide"] : ""
						}`}
				>
					<Link className={styles.logo} to={"/"}>
						<WebpImage src={logo} alt={"Logo"} />
					</Link>
					<div className={styles.info}>
						<div className={styles.links}>
							{/* <Link className={styles.link} to={'/dapp'}> */}
							{/* @ts-ignore */}
							{/* {t('header.dapp')} */}
							{/* </Link> */}
							<a className={styles.link} onClick={trigger}>
								{/* @ts-ignore */}
								{t("header.token")}
							</a>
							{/* <Link className={styles.link} to={'/mint'}> */}
							{/* @ts-ignore */}
							{/* {t('header.mint')} */}
							{/* </Link> */}
							<a
								className={styles.link}
								href={"https://docs.cryptoflats.io/"}
								target={"_blank"}
								rel={"noreferrer noopener"}
							>
								{/* @ts-ignore */}
								{t("header.litepaper")}
							</a>
						</div>
						<div className={styles.socials}>
							<a
								className={styles.social}
								href={"https://t.me/cflats"}
								target={"_blank"}
								rel={"noreferrer noopener"}
							>
								<WebpImage src={images.main.header.telegram} alt={"telegram"} />
							</a>
							<a
								className={styles.social}
								href={"https://x.com/cflatsproject"}
								target={"_blank"}
								rel={"noreferrer noopener"}
							>
								<WebpImage src={images.main.header.x} alt={"x"} />
							</a>
							<a
								className={styles.social}
								href={"https://discord.gg/cflats"}
								target={"_blank"}
								rel={"noreferrer noopener"}
							>
								<WebpImage src={images.main.header.discord} alt={"discord"} />
							</a>
						</div>
						<div className={styles.language}>
							<LanguageSwitcher className={styles["hide-while-not-mobile"]} />
						</div>
						{type === "main" && (
							<button className={styles.wallet} onClick={trigger}>
								<WebpImage src={images.main.header.wallet} alt={"wallet"} />
							</button>
						)}
						{type === "dapp" && (
							<WebpImage
								className={styles.avatar}
								src={images.main.header.avatar}
								alt="avatar"
							/>
						)}

						<Burger
							isOpen={isOpen}
							className={styles.burger}
							onClick={setIsOpen}
						/>
					</div>
				</div>
				<Dropdown
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					onToken={() => {
						setIsOpen(false);
						// setIsTokenSale(true)
						trigger();
					}}
				/>

				<ModalConnectWallet
					isOpen={isConnectWallet}
					onClose={() => {
						setIsBackground(false);
						setIsConnectWallet(false);
					}}
				/>
				<TokenSaleModal
					isOpen={isTokenSale}
					onClose={() => setIsTokenSale(false)}
				/>
			</header>
		</>
	);
};

type DropdownProps = {
	isOpen: boolean;
	onClose: () => void;
	onToken: () => void;
};

const Dropdown = React.memo<DropdownProps>(({ isOpen, onClose, onToken }) => {
	const { t } = useTranslation();

	return createPortal(
		<div className={`${styles.dropdown} ${isOpen ? styles["is-active"] : ""}`}>
			<div className={styles["dropdown-links"]}>
				<a className={styles["dropdown-link"]} onClick={onToken}>
					{/* @ts-ignore */}
					{t("header.token")}
				</a>
				{/* <Link className={styles['dropdown-link']} to={'/mint'} onClick={onClose}>*/}
				{/* @ts-ignore */}
				{/* {t('header.mint')} */}
				{/* </Link> */}
				{/* <Link className={styles['dropdown-link']} to={'/dapp'} onClick={onClose}>*/}
				{/* @ts-ignore */}
				{/* {t('header.dapp')} */}
				{/* </Link> */}
				<a
					className={styles["dropdown-link"]}
					href={"https://docs.cryptoflats.io/"}
					target={"_blank"}
					rel={"noreferrer noopener"}
					onClick={onClose}
				>
					{/* @ts-ignore */}
					{t("header.litepaper")}
				</a>

				<a className={styles["dropdown-link"]} href="https://t.me/Cflatsbot">
					Cflats game
				</a>
				<LanguageSwitcher className={"hide-while-not-mobile"} />
			</div>
			<div className={styles["dropdown-socials-wrapper"]}>
				<LanguageSwitcher className="hide-before-it-mobile" />

				<div className={styles["dropdown-socials"]}>
					<a
						className={styles.social}
						href={"https://t.me/cflats"}
						target={"_blank"}
						rel={"noreferrer noopener"}
						onClick={onClose}
					>
						<WebpImage src={images.main.header.telegram} alt={"telegram"} />
					</a>
					<a
						className={styles.social}
						href={"https://x.com/cflatsproject"}
						target={"_blank"}
						rel={"noreferrer noopener"}
						onClick={onClose}
					>
						<WebpImage src={images.main.header.x} alt={"x"} />
					</a>
					<a
						className={styles.social}
						href={"https://discord.gg/cflats"}
						target={"_blank"}
						rel={"noreferrer noopener"}
						onClick={onClose}
					>
						<WebpImage src={images.main.header.discord} alt={"discord"} />
					</a>
				</div>
			</div>
		</div>,
		document.querySelector("#root")!
	);
});

const LanguageSwitcher: React.FC<{ className?: string }> = ({ className }) => {
	const { i18n } = useTranslation();
	const [showDropdown, setShowDropdown] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [currentLang, setCurrentLang] = useState<string | null>(null);

	const changeLanguage = (lang: string) => {
		i18n.changeLanguage(lang);
		localStorage.setItem("saved-lang", lang);
		setCurrentLang(lang);
		setShowDropdown(false);
	};

	useEffect(() => {
		const lang = localStorage.getItem("saved-lang");

		if (lang) {
			changeLanguage(lang);
		}
	}, [currentLang]);

	const toggleDropdown = () => {
		setIsOpen((prev) => !prev);
	};

	return (
		<div
			className={[styles["language-switcher"], styles[className || ""]].join(
				" "
			)}
			onClick={toggleDropdown}
		>
			<div className={styles["active-area"]}>
				<span className="text">{currentLang}</span>
				{/* @ts-ignore */}
				<FaChevronDown
					className={[styles["chevron"], isOpen ? styles["active"] : ""].join(
						" "
					)}
				/>
			</div>
			{isOpen && (
				<div className={styles["_dropdown"]}>
					<div
						className={[
							styles["__dropdown-item"],
							currentLang === "en" ? styles["selected"] : "",
						].join(" ")}
						onClick={() => changeLanguage("en")}
					>
						en
					</div>
					<div
						className={[
							styles["__dropdown-item"],
							currentLang === "ru" ? styles["selected"] : "",
						].join(" ")}
						onClick={() => changeLanguage("ru")}
					>
						ru
					</div>
				</div>
			)}
		</div>
	);
};
